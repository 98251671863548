import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import RadioButtonRow from 'common/components/RadioButtonRow'
import NavigationButtons from 'common/components/NavigationButtons'
import ChooseBudget from 'job_wizard_mobile/components/steps/ChooseBudget'
import ChooseSchedule from 'job_wizard_mobile/components/steps/ChooseSchedule'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'

const BudgetPrice = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    let $job_modal = $('#job-modal')

    $('.choose-budget-price').addClass('hidden')
    $job_modal.data('bootstrapValidator').removeField('budget')
    $job_modal.data('bootstrapValidator').removeField('set_price')
    $job_modal.data('bootstrapValidator').removeField('price_range')

    JobWizardEventEmitter.emit('job:wizard:advanceToStep', ChooseBudget)
  },

  component: React.createClass({
    set_price_range_matrix: [
      { id: 0, text: 'Under $500',      value: '$1_-_$500',     className: 'under-500'},
      { id: 1, text: '$500 - $1000',    value: '$500_-_$1000',  className: 'five-hundred-1000'},
      { id: 2, text: '$1000 - $2000',   value: '$1000_-_$2000', className: 'thousand-200'},
      { id: 3, text: '$2000 - $5000',   value: '$2000_-_$5000', className: 'two-thousand-5000'},
      { id: 4, text: 'More than $5000', value: '$5001_-_$5001', className: 'more-than-5000'}
    ],

    getInitialState: function() {
      return this.getStateValues()
    },

    getStateValues: function() {
      return {
        budgetType:          data.budgetType,
        budgetPriceRange:    data.budgetPriceRange,
        budgetSetPriceRange: data.budgetSetPriceRange,
      }
    },

    componentDidMount: function() {
      if (data.budgetInMind === 'no' && data.backToSubmit === false) {
        this.props.onStepProgression()
      }

      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#budget-price-range').radioButtonGroup({activeRowClass: 'active'})

      $('#budget-price-range > * [class="radio"]').click(function(e) {
        actions.updateBudgetPriceRange(e.currentTarget.lastChild.lastChild.value)
      })

      this.componentDoesValidate()
    },

    budgetTypeChanged: function(e) {
      let val = $(e.currentTarget).data('value')
      actions.updateBudgetType(val)
      this.state = this.getStateValues()

      this.validator.updateStatus($("[name='budget']"), "VALID")
    },

    budgetPriceRangeChanged: function(e) {
      let $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]
      let [min, max] = $selectedItem.value.replace(/\$|_/g, '').split('-');

      actions.updateBudgetPriceRange(min, max)
      this.state = this.getStateValues()

      this.validator.updateStatus($("[name='price_range']"), "VALID")
      this.autoProgress()
    },

    setPriceChanged: function(e) {
      let val = $(e.currentTarget).val()
      actions.updateBudgetSetPriceRange(val)
      this.state = this.getStateValues()
    },

    autoProgress: function() {
      if (data.backToSubmit === true){
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
      }
      else {
        this.props.onStepProgression()
      }
    },

    isBudgePriceRangeChecked: function(priceRange) {
      let selectedPriceRange = data.budgetPriceRange;

      return selectedPriceRange === priceRange
    },

    componentDoesValidate: function() {
      this.validator.addField('budget',
        {
          validators: {
            notEmpty: {
              message: 'Please select a "Budget" type'
            }
          }
        }
      )

      this.validator.addField('set_price',
        {
          validators: {
            notEmpty: {
              message: 'Please enter a "Set Price"'
            },
            regexp: {
              regexp: /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/,
              message: 'Please enter a dollar amount for the price'
            }
          }
        }
      )

      this.validator.addField('price_range',
        {
          validators: {
            notEmpty: {
              message: 'Please select a "Price Range"'
            }
          }
        }
      )
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden = `${radioButtonVisible} hidden`

      return (
        <div className="form-group choose-budget-price">
          <div className="tab-content" id="price-range-or-set">
            <div role="tabpanel" className="tab-pane form-group active" id="budget-yes">
              <div className="radio-button-box minor-bold" id="budget-selection">
                <div className="form-group mt15">
                  <div  className={"radio " +((this.state.budgetType === "price_range") ? "active" : "")} role="tab" data-value="price_range"  data-toggle="tab" href="#budget-price-range" onClick={this.budgetTypeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10 price_range">Price Range</div>
                      <div className={(this.state.budgetType === "price_range") ? radioButtonVisible : radioButtonHidden}>
                        <i className="ficon-circle-x pull-right"></i>
                      </div>
                      <input type="radio"  defaultChecked={(this.state.budgetType === "price_range") ? 'checked' : ''} id="budget" name="budget" value="price_range" className="form-control invisible"/>
                    </div>
                  </div>
                  <div className={"radio " + ((this.state.budgetType === "set_price") ? "active" : '')} role="tab" data-value="set_price" data-toggle="tab" href="#budget-set-price" onClick={this.budgetTypeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10">Set Price</div>
                      <div className={(this.state.budgetType === "set_price") ? radioButtonVisible : radioButtonHidden}>
                        <i className="ficon-circle-x pull-right"></i>
                      </div>
                      <input type="radio"  defaultChecked={(this.state.budgetType === "set_price") ? 'checked' : ''} id="budget" name="budget" value="set_price" className="form-control invisible"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content" id="budget-amount">
            <div role="tabpanel" className={"tab-pane form-group "  + ((this.state.budgetType === "set_price") ? "active" : "")} id="budget-set-price">
              <input type="text" id="set_price" name="set_price" defaultValue={this.state.budgetSetPriceRange} className="form-control" placeholder="e.g. $250.00" onChange={this.setPriceChanged} />
              <span className="pull-right pt10">
                <NavigationButtons
                  navButtonLayout='okButton'
                  onStepProgression={this.props.onStepProgression}
                />
              </span>
            </div>

            <div role="tabpanel" className={"tab-pane form-group " + ((this.state.budgetType === "price_range") ? "active" : "")} id="budget-price-range">
              <div className="radio-button-box minor-bold">
                <div className="form-group mt15">
                  {this.set_price_range_matrix.map((priceRange) => {
                    return (
                      <RadioButtonRow
                        name="price_range"
                        key={priceRange.id}
                        text={priceRange.text}
                        id={priceRange.id}
                        className={priceRange.className}
                        value={priceRange.value}
                        isChecked={this.isBudgePriceRangeChecked(priceRange.value)}
                        onClickHandler={this.budgetPriceRangeChanged}
                      />
                    )
                  }, this)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default BudgetPrice
