import 'bootstrap-sass/assets/javascripts/bootstrap/carousel'
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown'
import 'bootstrap-sass/assets/javascripts/bootstrap/modal'
import 'bootstrap-sass/assets/javascripts/bootstrap/tab'
import 'bootstrap-sass/assets/javascripts/bootstrap/alert'
import 'bootstrap-select/dist/css/bootstrap-select'
import 'bootstrap-select/dist/js/bootstrap-select.min'
import 'common/src/lazy_load_images.js'
import 'common/src/nav/index' // nav
import 'common/styles/index.scss'
import 'velocity-animate'
import "@babel/polyfill" //must come first
import {} from 'jquery-ujs'
import MessageNavigation from 'common/components/chat/MessageNavigation'
import React from 'react'
import ReactDOM from 'react-dom'
import Wizard from 'common/components/job_wizard/Wizard'
import '../jquery-plugins/lazyload.js'


document.addEventListener('DOMContentLoaded', () => {
  const jobWizard = document.getElementById('job_wizard')
  const unseenConversationNodes = document.getElementsByClassName('unseen_conversation_count')

  if (jobWizard !== null ) {
    const jobWizardData = JSON.parse(jobWizard.getAttribute('data').replace(/&quot;/g,'"'))
    ReactDOM.render(<Wizard {...jobWizardData}/>, jobWizard)
  }


  const openAI = document.getElementById('open-ai-container')
  const openAIData = openAI && JSON.parse(openAI.getAttribute('data').replace(/&quot;/g,'"'))
  const aiAssistance = document.getElementById('ai-assistance-container')
  const aiAssistanceData = aiAssistance && JSON.parse(aiAssistance.getAttribute('data').replace(/&quot;/g,'"'))

  if (openAI) {
    ReactDOM.render(<OpenAISettings {...openAIData}/>, openAI)
  }

  Array.prototype.forEach.call(unseenConversationNodes, unseenConversationNode => {
    if (unseenConversationNode != null) {
      const unseenConversationData = JSON.parse(unseenConversationNode.getAttribute('data').replace(/&quot;/g, '"'))
      ReactDOM.render( < MessageNavigation {...unseenConversationData} /> , unseenConversationNode)
    }
  })

  if (aiAssistance) {
    ReactDOM.render(<EditAIAssistance {...aiAssistanceData}/>, aiAssistance)
  }
})
